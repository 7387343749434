import React from 'react';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { updateMeetingTitle } from '@setup/api/meeting/meeting';
import MeetingDialogView from './MeetingDialogView';

export default function MeetingDialog(props) {
    const {
        open,
        handleClose,
        loadingDialog,
        currentMeeting,
        findAndUpdateMeeting,
        handleOpenMeeting
    } = props;

    const navigate = useNavigate();

    const handleOpenMeetingPage = () => {
        navigate(`/meeting/${currentMeeting.id}`);
    };

    const handleUpdateTitle = async (title) => {
        try {
            await updateMeetingTitle({ meetingId: currentMeeting.id, title });
            findAndUpdateMeeting({ id: currentMeeting.id, title });
        } catch (error) {
            console.error(error);
        }
    };

    if (currentMeeting && open) {
        return (
            <MeetingDialogView
                open={open}
                handleClose={handleClose}
                handleOpenMeetingPage={handleOpenMeetingPage}
                findAndUpdateMeeting={findAndUpdateMeeting}
                handleUpdateTitle={handleUpdateTitle}
                loadingDialog={loadingDialog}
                currentMeeting={currentMeeting}
                handleOpenMeeting={handleOpenMeeting}
            />
        );
    }
}

MeetingDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    transcription: PropTypes.object,
    loadingDialog: PropTypes.bool,
    currentMeeting: PropTypes.object,
    findAndUpdateMeeting: PropTypes.func,
    handleOpenMeeting: PropTypes.func.isRequired
};
