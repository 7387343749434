import React, { useEffect, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import { PropTypes } from 'prop-types';
import { meetingStatus } from '@common/helpers/meetingStatus';
import { useGetTagData } from '@common/hooks/useGetTagData';
import { utcToLocaleDate } from '@common/utils/date';
import {
    getNumberOfParticipants,
    isProcessingState,
    isRecordingState,
    isStandByState,
    shouldBeOnErrorState
} from '@pages/meetingsRepository/utils';
import { getMeetingById } from '@setup/api/meeting/meeting';
import ActionsCell from './tableCells/ActionsCell';
import DateCell from './tableCells/DateCell';
import InfoCell from './tableCells/InfoCell';
import TitleCell from './tableCells/TitleCell';

TableRowView.propTypes = {
    handleOpenMeeting: PropTypes.func,
    meeting: PropTypes.object,
    handleDeleteMeeting: PropTypes.func,
    handleRetryMeeting: PropTypes.func,
    findAndUpdateMeeting: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired
};

export default function TableRowView({
    handleOpenMeeting,
    meeting,
    status,
    handleDeleteMeeting,
    handleRetryMeeting,
    findAndUpdateMeeting
}) {
    const [hover, setHover] = useState(false);
    const [isProcessing, setIsProcessing] = useState(isProcessingState(status));
    const [isRecording, setIsRecording] = useState(
        isRecordingState({
            updatedAt: meeting.updatedAt,
            status: meeting.transcriptionStatus,
            filename: meeting.filename
        })
    );
    // const [isStandBy, setIsStandBy] = useState(isStandByState(status));
    const { currentTagData } = useGetTagData(meeting.tag);

    const date = utcToLocaleDate({ utcDate: meeting.createdAt, timeZone: 'es-ES' });
    const numberOfParticipants = getNumberOfParticipants(meeting.speakers);

    useEffect(() => {
        let timerInterval;
        if (
            meeting &&
            !shouldBeOnErrorState({
                createdAt: meeting.createdAt,
                updatedAt: meeting.updatedAt,
                status: meeting.transcriptionStatus
            }) &&
            (isProcessing || isRecording)
        ) {
            if (!timerInterval) {
                timerInterval = setInterval(() => {
                    (async () => {
                        const { meeting: updatedMeeting } = await getMeetingById({
                            meetingId: meeting.id
                        });
                        if (isRecording) {
                            findAndUpdateMeeting({
                                id: updatedMeeting.id,
                                updatedAt: updatedMeeting.updatedAt
                            });
                            setIsRecording(
                                isRecordingState({
                                    updatedAt: updatedMeeting.updatedAt,
                                    status: updatedMeeting.transcriptionStatus,
                                    filename: updatedMeeting.filename
                                })
                            );
                        } else if (
                            meeting.transcriptionStatus !== updatedMeeting.transcriptionStatus
                        ) {
                            findAndUpdateMeeting({
                                id: updatedMeeting.id,
                                updatedAt: updatedMeeting.updatedAt,
                                status: updatedMeeting.transcriptionStatus
                            });
                            if (
                                updatedMeeting.transcriptionStatus ===
                                    meetingStatus.SUMMARY_SUCCEED ||
                                shouldBeOnErrorState({
                                    createdAt: meeting.createdAt,
                                    updatedAt: meeting.updatedAt,
                                    status: meeting.transcriptionStatus
                                })
                            ) {
                                clearInterval(timerInterval);
                                setIsProcessing(false);
                            }
                        }
                    })();
                }, 9000);
            }
        } else if (timerInterval) {
            clearInterval(timerInterval);
        }

        return () => clearInterval(timerInterval);
    }, [meeting]);

    useEffect(() => {
        const inProgress = isProcessingState(status);
        if (inProgress) {
            setIsProcessing(true);
            setIsRecording(false);
        }
    }, [status]);

    return (
        <TableRow
            key={meeting.id}
            hover={true}
            onMouseLeave={() => setHover(false)}
            onMouseEnter={() => setHover(true)}
        >
            <TitleCell
                handleOpenMeeting={handleOpenMeeting}
                hover={hover}
                meeting={meeting}
                errorState={shouldBeOnErrorState({
                    createdAt: meeting.createdAt,
                    updatedAt: meeting.updatedAt,
                    status: meeting.transcriptionStatus
                })}
                isRecording={isRecording}
            />
            <InfoCell
                handleOpenMeeting={handleOpenMeeting}
                currentTagData={currentTagData}
                numberOfParticipants={numberOfParticipants}
                meeting={meeting}
                errorState={shouldBeOnErrorState({
                    createdAt: meeting.createdAt,
                    updatedAt: meeting.updatedAt,
                    status: meeting.transcriptionStatus
                })}
                hover={hover}
                isStandBy={isStandByState(status)}
                isRecording={isRecording}
            />
            <DateCell
                handleOpenMeeting={handleOpenMeeting}
                date={date}
                errorState={shouldBeOnErrorState({
                    createdAt: meeting.createdAt,
                    updatedAt: meeting.updatedAt,
                    status: meeting.transcriptionStatus
                })}
                meeting={meeting}
            />
            <ActionsCell
                meeting={meeting}
                status={status}
                handleDeleteMeeting={handleDeleteMeeting}
                errorState={shouldBeOnErrorState({
                    createdAt: meeting.createdAt,
                    updatedAt: meeting.updatedAt,
                    status: meeting.transcriptionStatus
                })}
                handleRetryMeeting={handleRetryMeeting}
                isProcessing={isProcessing}
                isStandBy={isStandByState(status)}
                isRecording={isRecording}
                findAndUpdateMeeting={findAndUpdateMeeting}
                setCurrentMeeting={meeting}
            />
        </TableRow>
    );
}
