import React, { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { meetingStatus } from '@common/helpers/meetingStatus';
import { navigation } from '@common/helpers/navigation';
import selectorRoles from '@common/helpers/selectorRoles.json';
import useNotification from '@common/hooks/useNotification';
import useUser from '@common/hooks/useUser';
import { getAudioDuration } from '@common/utils/audio';
import { downloadFileFromBlob } from '@common/utils/downloadFileFromBlob';
import { getReadSignedURL, getWriteSignedUrl, uploadToGCS } from '@setup/api/gcs';
import {
    createMeeting,
    mergeAudio,
    updateMeetingTag,
    updateMeetingTitle,
    updateSpeakers
} from '@setup/api/meeting/meeting';
import { getReportTemplateById } from '@setup/api/reportTemplates/reportTemplates';
import CreateMeetingDialogView from './CreateMeetingDialogView';
import CreateMeetingMethodSelection from './CreateMeetingMethodSelection';
import DialogLoadingState from './DialogLoadingState';
import DialogUsageLimit from './DialogUsageLimit';
import {
    showDurationAlert,
    showFileAlert,
    showFormatAlert,
    showSpeakersAlert,
    showSpeakersRoleAlert,
    showSpeakersTurnAlert,
    showTitleAlert
} from './utils';

CreateMeetingDialog.propTypes = {
    findAndUpdateMeeting: PropTypes.func.isRequired,
    isDirectUpload: PropTypes.bool.isRequired,
    currentMeeting: PropTypes.object,
    // handleDeleteMeeting: PropTypes.func,
    handleRetryMeeting: PropTypes.func,
    introduceNewMeeting: PropTypes.func,
    openFromButton: PropTypes.bool,
    setOpenButtonDialog: PropTypes.func,
    setIsAudioFromExtension: PropTypes.func,
    createdFile: PropTypes.any,
    createdFilename: PropTypes.string,
    isRecordedFromVoicit: PropTypes.bool
};

export default function CreateMeetingDialog({
    currentMeeting,
    // handleDeleteMeeting,
    handleRetryMeeting,
    findAndUpdateMeeting,
    isDirectUpload,
    introduceNewMeeting,
    openFromButton,
    setOpenButtonDialog,
    setIsAudioFromExtension,
    createdFile,
    isRecordedFromVoicit
}) {
    const navigate = useNavigate();
    const notification = useNotification();
    const userUseCase = useSelector((state) => state.user.userUseCase);
    const userGivenName = useSelector((state) => state.user.userGivenName);
    const userPlan = useSelector((state) => state.user.userPlan);
    const { userIsRunOutOfMinutes } = useUser();
    const audioMerge = useRef(false);

    const [title, setTitle] = useState('');
    const [file, setFile] = useState(createdFile ? createdFile : '');
    const [filename, setFilename] = useState('');
    const [audioDuration, setAudioDuration] = useState('');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(openFromButton ? openFromButton : false);
    const [alertTitle, setAlertTitle] = useState(false);
    const [alertFile, setAlertFile] = useState(false);
    const [alertSpeaker, setAlertSpeaker] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [speakers, setSpeakers] = useState([]);
    const [tag, setTag] = useState();
    const [template, setTemplate] = useState();
    const [audioUrl, setAudioUrl] = useState('');
    const [closeConfirmationOpen, setCloseConfirmationOpen] = useState(false);
    const [meetingCreated, setMeetingCreated] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        setTitle('');
        setFilename('');
        setFile('');
        setAlertMessage('');
        setAlertTitle(false);
        setLoading(false);
    };
    const handleClose = async ({ closingOnSuccess } = {}) => {
        if (
            (closingOnSuccess !== true && !isDirectUpload) ||
            (closingOnSuccess !== true && isRecordedFromVoicit)
        ) {
            if (closeConfirmationOpen) {
                // ! Below flow cancelled due to meeting deletion on plugin and retry
                // if (!isRecordedFromVoicit && currentMeeting.id) {
                //     // Cancelling GCS flow
                //     await handleDeleteMeeting(currentMeeting.id);
                // }
                handleCleanDependencies();
                navigate('/');
            }
            setCloseConfirmationOpen(!closeConfirmationOpen);
        } else {
            handleCleanDependencies();
            if (isDirectUpload) {
                setOpenButtonDialog(false);
            } else {
                navigate('/');
            }
        }
    };

    const handleDeleteFile = () => {
        setFilename('');
        setFile('');
        setTitle('');
    };

    const handleDownload = () => {
        downloadFileFromBlob({
            blob: file,
            filename: filename,
            extension: 'mp3'
        });
    };

    const handleOnFileChange = async (file) => {
        try {
            const duration = await getAudioDuration(file).catch(() => {
                return null;
            });

            if (
                showFormatAlert({ file, setAlertMessage, setAlertFile }) ||
                showDurationAlert({
                    duration,
                    planDurationLimit: userPlan.meetingMinutesDuration,
                    setAlertMessage,
                    setAlertFile
                })
            )
                return;

            setFile(file);
            setAudioDuration(duration);
            setFilename(file.name);
            setTitle(file.name);
            setAlertFile(false);
            setAlertMessage('');
        } catch (error) {
            setFilename('');
            console.error(error);
        }
    };

    const handleNavigateToRecorder = () => {
        navigate(navigation.app.routes.recorder);
        window.location.reload();
    };

    const handleCleanDependencies = () => {
        setTitle('');
        setFilename('');
        setFile('');
        setAlertMessage('');
        setAlertTitle(false);
        setLoading(false);
        setOpen(false);
        setAlertFile(false);
        setAlertSpeaker(false);
        setSpeakers([]);
        setTemplate();
        setTag();
    };

    const handleOnTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const setFailureNotification = ({ audioFailure }) => {
        if (audioFailure) {
            notification('audio-failure');
        } else {
            notification('meeting-failure');
        }
    };

    /**
     * Check for possible errors
     */
    const alertFilter = () => {
        if (isDirectUpload && showFileAlert({ file, setAlertMessage, setAlertFile })) {
            return true;
        }
        if (userUseCase === selectorRoles[0].type) {
            if (
                showSpeakersAlert({
                    currentSpeakers: speakers,
                    setAlertMessage,
                    setAlertSpeaker
                })
            ) {
                return true;
            }
        }
        if (
            showTitleAlert({ title, setAlertMessage, setAlertTitle }) ||
            showSpeakersTurnAlert({
                currentSpeakers: speakers,
                setAlertMessage,
                setAlertSpeaker
            }) ||
            showSpeakersRoleAlert({
                currentSpeakers: speakers,
                setAlertMessage,
                setAlertSpeaker
            })
        ) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * For creating direct uploaded or recorded meetings
     */
    const handleDirectUploadCreateMeeting = async (e) => {
        e.preventDefault();
        const filetype = file.type || 'unknown';
        // Don't check if speakers, it is optional
        if (alertFilter()) return;
        if (loading) return; // To ensure we avoid repeated api calls

        setLoading(true);
        if (!audioDuration) {
            handleClose();
            setFailureNotification({ audioFailure: true });
            return;
        }
        const data = {
            title,
            filename: uuidv4(),
            duration: audioDuration,
            speakers
        };

        try {
            const signedUrl = await getWriteSignedUrl({
                filename: `recordings/${data.filename}`,
                filetype
            });
            await uploadToGCS({ signedUrl, file });
            const { meeting } = await createMeeting({
                title,
                filename: data.filename,
                duration: audioDuration,
                speakers,
                tag: tag?.name || null,
                templateId: template.id,
                platform: isRecordedFromVoicit ? 'Voicit' : 'directUpload'
            });
            introduceNewMeeting(meeting);
            setMeetingCreated(true);
            handleClose({ closingOnSuccess: true });
            if (!userIsRunOutOfMinutes) notification('transcription-processing');
        } catch (error) {
            setLoading(false);
            setFailureNotification({ audioFailure: false });
        }
    };

    /**
     * For creating plugin uploaded meetings
     */
    const handlePluginCreateMeeting = async () => {
        const meetingId = currentMeeting.id;
        try {
            if (alertFilter()) return;
            if (loading) return; // To ensure we avoid repeated api calls

            setLoading(true);

            // Data update stage
            if (currentMeeting.title !== title) await updateMeetingTitle({ meetingId, title });
            if (tag?.name) await updateMeetingTag({ meetingId, tag: tag.name });
            await updateSpeakers({ meetingId, speakers });
            await findAndUpdateMeeting({
                label: tag?.name || null,
                title: currentMeeting.title !== title ? title : null,
                id: meetingId,
                status: meetingStatus.AUDIO_STARTED
            });

            // At this point the user is not leaving and we will need to disable it to reload the page
            window.removeEventListener('beforeunload', onTabClose, { capture: true });

            // Meeting process starting stage
            await handleRetryMeeting({
                meeting: { id: meetingId },
                speakers,
                templateId: template.id
            });

            handleClose({ closingOnSuccess: true });
            notification('transcription-processing');
            setIsAudioFromExtension(false); // To avoid opening the dialog again
        } catch (error) {
            setFailureNotification({ audioFailure: false });
        }
    };

    const initializeSpeakers = (initialSpeakers) => {
        const numberOfSpeakers = initialSpeakers.length;
        const useCaseRoles =
            userUseCase === selectorRoles[0].type ||
            userUseCase === selectorRoles[1].type ||
            userUseCase === selectorRoles[2].type
                ? selectorRoles.filter((useCaseRoles) => useCaseRoles.type === userUseCase)
                : null;

        return initialSpeakers.map((speaker) => {
            if (numberOfSpeakers === 2 && useCaseRoles && useCaseRoles[0].roles.length === 2) {
                const roleIndex = speaker === userGivenName ? 1 : 0; // The user always do the role of non focused speaker
                return {
                    name: speaker,
                    turn: -1,
                    roleLabel: useCaseRoles[0].roles[roleIndex].label,
                    focus: useCaseRoles[0].roles[roleIndex].focus
                };
            } else {
                return { name: speaker, turn: -1, roleLabel: '', focus: false };
            }
        });
    };

    // For avoid user leaving the page without saving
    const onTabClose = (event) => {
        // Cancel the event as the user is trying to leave the page
        event.preventDefault();
        // Chrome requires returnValue to be set
        event.returnValue = '';
    };

    useEffect(() => {
        if (setOpenButtonDialog) {
            setOpen(openFromButton);
        }
    }, [openFromButton]);

    useEffect(() => {
        if (open && userIsRunOutOfMinutes) {
            setAlertFile(true);
            setAlertMessage('No dispones de más minutos de uso este mes');
        }
    }, [open]);

    useEffect(() => {
        (async () => {
            if (currentMeeting && !isDirectUpload) {
                setOpen(true);
                setTitle(currentMeeting.title ? currentMeeting.title : 'Sin título');
                setFilename(currentMeeting.title ? currentMeeting.title : 'Sin título');
                setSpeakers(initializeSpeakers(currentMeeting.speakers));
                setAudioDuration(currentMeeting.duration);
                // Below is just used for retrying
                if (currentMeeting.templateId) {
                    const { data } = await getReportTemplateById({
                        reportTemplateId: currentMeeting.templateId
                    });
                    setTemplate(data);
                }
                try {
                    if (currentMeeting.filename) {
                        const url = await getReadSignedURL({
                            filename: currentMeeting.filename,
                            removeType: true
                        });
                        setAudioUrl(url);
                    } else if (!audioMerge.current) {
                        // No filename means audio hasn´t been merged yet
                        audioMerge.current = true; // Set ref to not repeat mergeAudio calls
                        const { filename } = await mergeAudio(currentMeeting.id).catch(
                            () => (audioMerge.current = false)
                        );
                        const url = await getReadSignedURL({
                            filename,
                            removeType: true
                        });
                        findAndUpdateMeeting({ id: currentMeeting.id, filename }); // Updates the meeting data locally
                        setAudioUrl(url);
                    }
                } catch (e) {
                    notification('audio-failure-ext');
                    handleClose({ closingOnSuccess: true });
                }
            }
        })();
    }, [currentMeeting]);

    /**
     * To upload the recorder audio file from Voicit recording page
     */
    useEffect(() => {
        if (createdFile) {
            setFile(createdFile);
            handleOnFileChange(createdFile);
        }
    }, [createdFile]);

    useEffect(() => {
        if (open && !isDirectUpload && !isRecordedFromVoicit) {
            // Just for plugin meetings
            // TODO: delete once the new plugin flow is uploaded
            const timeoutId = setTimeout(() => {
                handleClose({ closingOnSuccess: true });
            }, 570000); // 9 minutes and 30 seconds
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [open]);

    useEffect(() => {
        if (open) {
            // Add event listener when the component mounts
            window.addEventListener('beforeunload', onTabClose);

            // Remove event listener when the component unmounts
            return () => {
                window.removeEventListener('beforeunload', onTabClose);
            };
        }
    }, []);

    if ((!isDirectUpload && userIsRunOutOfMinutes) || (userIsRunOutOfMinutes && meetingCreated)) {
        return <DialogUsageLimit />;
    } else if ((file || audioUrl) && filename) {
        return (
            <CreateMeetingDialogView
                handleOnTitleChange={handleOnTitleChange}
                title={title}
                loading={loading}
                handleClose={handleClose}
                open={open}
                alertTitle={alertTitle}
                alertFile={alertFile}
                alertMessage={alertMessage}
                alertSpeaker={alertSpeaker}
                filename={filename}
                file={file}
                speakers={speakers}
                tag={tag}
                setTag={setTag}
                template={template}
                setTemplate={setTemplate}
                setSpeakers={setSpeakers}
                audioDuration={audioDuration}
                audioUrl={audioUrl}
                handleCreateMeeting={
                    !isDirectUpload && audioUrl
                        ? handlePluginCreateMeeting
                        : handleDirectUploadCreateMeeting
                }
                closeConfirmationOpen={
                    isDirectUpload && !isRecordedFromVoicit ? false : closeConfirmationOpen
                }
                setCloseConfirmationOpen={setCloseConfirmationOpen}
                isDirectUpload={isDirectUpload}
                handleDeleteFile={isDirectUpload ? handleDeleteFile : null}
                handleDownload={!isDirectUpload && file ? handleDownload : null}
                handleClickOpen={isDirectUpload ? handleClickOpen : null}
            />
        );
    } else if (isDirectUpload && open && !isRecordedFromVoicit) {
        return (
            <CreateMeetingMethodSelection
                filename={filename}
                alertMessage={alertMessage}
                alertFile={alertFile}
                handleNavigateToRecorder={handleNavigateToRecorder}
                handleOnFileChange={handleOnFileChange}
                handleClose={handleClose}
            />
        );
    } else if (open) {
        return <DialogLoadingState />;
    }
}
