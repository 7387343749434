import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogTitle, Grid, Slide, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import AudioPlayer from '@common/components/audioPlayer/AudioPlayer';
import TypographyEditable from '@common/components/TypographyEditable';
import { meetingStatus } from '@common/helpers/meetingStatus';
import useUser from '@common/hooks/useUser';
import palette from '@common/theme/palette/palette';
import { downloadAudio } from '@common/utils/audio';
import { isProcessingState, isStandByState } from '@pages/meetingsRepository/utils';
import { getReadSignedURL } from '@setup/api/gcs';
import { mergeAudio } from '@setup/api/meeting/meeting';
import UsageWarning from '../createMeetingDialog/UsageWarning';
import Info from './info/Info';

const useStyles = makeStyles(() => ({
    dialog: {
        height: '75vh'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function MeetingDialogView(props) {
    const { open, handleClose, handleUpdateTitle, currentMeeting, findAndUpdateMeeting } = props;
    const classes = useStyles();
    const audioUrl = useRef('');
    const audioMerge = useRef(false);
    const { userIsRunOutOfMinutes } = useUser();

    const [, setAudioMerged] = useState(false); // Just used to trigger the render on URL update after merge audio segments.

    const isProcessing = isProcessingState(currentMeeting.transcriptionStatus);
    const isStandBy = isStandByState(currentMeeting.transcriptionStatus);
    const isAudioError = currentMeeting.transcriptionStatus === meetingStatus.AUDIO_ERROR;

    useEffect(() => {
        (async () => {
            if (currentMeeting) {
                if (currentMeeting.filename) {
                    audioUrl.current = await getReadSignedURL({
                        filename: currentMeeting.filename,
                        removeType: isStandBy
                    });
                } else if (!audioMerge.current) {
                    // No filename means audio hasn´t been merged yet
                    audioMerge.current = true; // Set ref to not repeat mergeAudio calls
                    const { filename } = await mergeAudio(currentMeeting.id).catch(
                        () => (audioMerge.current = false)
                    );
                    audioUrl.current = await getReadSignedURL({
                        filename,
                        removeType: isStandBy
                    });
                    findAndUpdateMeeting({ id: currentMeeting.id, filename }); // Updates the meeting data locally
                    setAudioMerged(true);
                }
            }
        })();
    }, []);

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={'sm'}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby='alert-dialog-slide-description'
            sx={{ height: '100vh' }}
            classes={{ paper: classes.dialog }}
        >
            <DialogTitle sx={{ mb: 2 }}>
                <TypographyEditable
                    handleChangeText={handleUpdateTitle}
                    defaultText={currentMeeting.title}
                    fontSize='h4'
                />
            </DialogTitle>

            <DialogContent>
                <Info currentMeeting={currentMeeting} findAndUpdateMeeting={findAndUpdateMeeting} />
                {isProcessing || isStandBy ? (
                    <>
                        <AudioPlayer
                            sx={{ mt: 2, mb: 3 }}
                            filename={currentMeeting.title}
                            subtitle={
                                currentMeeting.platform
                                    ? currentMeeting.platform === 'directUpload'
                                        ? 'Subida directa'
                                        : currentMeeting.platform
                                    : null
                            }
                            audioUrl={audioUrl.current}
                            audioDuration={currentMeeting.duration}
                            handleDownload={
                                isStandBy
                                    ? null
                                    : async () =>
                                          await downloadAudio({
                                              meetingTitle: currentMeeting.title,
                                              audioFilename: currentMeeting.filename
                                          })
                            }
                            enablePlaybackRate={true}
                        />
                        {userIsRunOutOfMinutes && <UsageWarning />}
                        {isStandBy && (
                            <Grid container direction='column' alignItems='center' sx={{ mt: 2 }}>
                                <Grid item sx={{ flexGrow: 1 }}>
                                    <Typography
                                        component='span'
                                        variant='body1'
                                        color={palette.primary[400]}
                                    >
                                        Haz clic en el botón Crear para generar tu informe.
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </>
                ) : isAudioError ? (
                    // This should´t happen into production, just in dev due to render duplicity
                    <Grid container direction='column' alignItems='center' sx={{ mt: 2 }}>
                        <Grid item sx={{ flexGrow: 1 }}>
                            <Typography
                                component='span'
                                variant='body1'
                                color={palette.primary[400]}
                            >
                                Existe un problema con el audio, contacta con soporte@voicit.com
                            </Typography>
                        </Grid>
                    </Grid>
                ) : null}
            </DialogContent>
        </Dialog>
    );
}

MeetingDialogView.propTypes = {
    open: PropTypes.bool,
    loadingDialog: PropTypes.bool,
    currentMeeting: PropTypes.object,
    handleClose: PropTypes.func,
    handleOpenMeetingPage: PropTypes.func,
    handleDeleteSummary: PropTypes.func,
    handleUpdateTitle: PropTypes.func,
    handleCreateSummary: PropTypes.func,
    summaryCreationLoader: PropTypes.bool,
    handleOpenMeeting: PropTypes.func.isRequired,
    summaryTopics: PropTypes.arrayOf(PropTypes.string),
    findAndUpdateMeeting: PropTypes.func
};
