import React from 'react';
import { PropTypes } from 'prop-types';
import TableRowView from './TableRowView';

export default function TableRow({
    handleOpenMeeting,
    meetingList,
    handleDeleteMeeting,
    handleRetryMeeting,
    findAndUpdateMeeting
}) {
    return (
        <>
            {meetingList.map((meeting) => (
                <TableRowView
                    key={meeting.id}
                    meeting={meeting}
                    status={meeting.transcriptionStatus}
                    handleOpenMeeting={handleOpenMeeting}
                    handleDeleteMeeting={handleDeleteMeeting}
                    handleRetryMeeting={handleRetryMeeting}
                    findAndUpdateMeeting={findAndUpdateMeeting}
                />
            ))}
        </>
    );
}

TableRow.propTypes = {
    handleOpenMeeting: PropTypes.func,
    meetingList: PropTypes.arrayOf(PropTypes.object),
    handleDeleteMeeting: PropTypes.func,
    handleRetryMeeting: PropTypes.func,
    findAndUpdateMeeting: PropTypes.func.isRequired
};
