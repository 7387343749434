import { urls } from '@common/helpers/urls';
import { invitateToVoicit } from '@setup/api/user';

export const sendUserInvitationToVoicit = async (emailsToInvite, userEmail, tag, authToken) => {
    for (const email of emailsToInvite) {
        await invitateToVoicit({ to: email, authToken: authToken });
    }
    await invitateToVoicit({
        to: urls.emails.noreply,
        toName: 'No Reply',
        content: `Usuario: ${userEmail} / Ha invitado a: ${emailsToInvite} / ${tag} ||  `,
        authToken: authToken
    });
};

export const sendChromeStoreNotification = async (userName, userEmail, authToken) => {
    await invitateToVoicit({
        to: urls.emails.alvaro,
        toName: 'Chrome Store Review Check',
        content: `Chrome Store Review Clicked => Nombre: ${userName} / Correo: ${userEmail}. `,
        authToken: authToken
    });
};
