import React, { useEffect, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import palette from '@common/theme/palette/palette';

export default function TitleCell({ handleOpenMeeting, hover, meeting, errorState, isRecording }) {
    const [meetingTitle, setMeetingTitle] = useState(meeting.title);

    useEffect(() => {
        setMeetingTitle(meeting.title);
    }, [meeting.title]);

    return (
        <TableCell
            sx={{
                borderColor: palette.primary[50],
                borderLeft: hover ? 2 : 0,
                borderLeftColor: palette.primary.main,
                boxSizing: 'border-box',
                cursor: errorState || isRecording ? null : 'pointer'
            }}
            style={{ width: 640 }}
            key={meetingTitle}
            component='th'
            scope='row'
            onClick={errorState || isRecording ? null : () => handleOpenMeeting(meeting)}
            onMouseDown={(event) => {
                if (event.button === 1) handleOpenMeeting(meeting, event);
            }}
        >
            <Typography variant='body1' color={palette.primary.main} ml={hover ? -0.1 : 0}>
                {meetingTitle}
            </Typography>
        </TableCell>
    );
}

TitleCell.propTypes = {
    handleOpenMeeting: PropTypes.func,
    meeting: PropTypes.object,
    hover: PropTypes.bool,
    errorState: PropTypes.bool,
    isRecording: PropTypes.bool.isRequired
};
