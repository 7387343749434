import React, { useState } from 'react';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import GraphicEqOutlinedIcon from '@mui/icons-material/GraphicEqOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, Grid, TableCell, TextField } from '@mui/material';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from '@common/components/dialogs/ConfirmationDialog';
import DialogButton from '@common/components/dialogs/DialogButton';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import ShareMeetingDialog from '@common/components/dialogs/ShareMeetingDialog';
import MenuButton from '@common/components/MenuButton';
import TagMeetingProcessingStatus from '@common/components/tagsComponents/TagMeetingProcessingStatus';
import { meetingStatus } from '@common/helpers/meetingStatus';
import { navigation } from '@common/helpers/navigation';
import { subscriptionPlans } from '@common/helpers/user';
import useNotification from '@common/hooks/useNotification';
import useUser from '@common/hooks/useUser';
import palette from '@common/theme/palette/palette';
import { downloadAudio } from '@common/utils/audio';
import { updateMeetingTitle } from '@setup/api/meeting/meeting';

ActionsCell.propTypes = {
    handleDeleteMeeting: PropTypes.func,
    handleRetryMeeting: PropTypes.func,
    meeting: PropTypes.object,
    errorState: PropTypes.bool.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    findAndUpdateMeeting: PropTypes.func.isRequired,
    setCurrentMeeting: PropTypes.func.isRequired,
    isStandBy: PropTypes.bool.isRequired,
    isRecording: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired
};

export default function ActionsCell({
    meeting,
    handleDeleteMeeting,
    errorState,
    isProcessing,
    findAndUpdateMeeting,
    isStandBy,
    isRecording,
    status
}) {
    const navigate = useNavigate();
    const notification = useNotification();
    const { getPlan, userIsRunOutOfMinutes } = useUser();

    const [openShareDialog, setOpenShareDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
    const [openDialog, setOpenEditTitleDialog] = useState(false);
    const [newMeetingName, setNewMeetingName] = useState(meeting.title);

    const handleOpenMeetingPage = () => {
        navigate(`/meeting/${meeting.id}`);
    };

    const handleOpenShareDialog = () => {
        setOpenShareDialog(true);
    };

    const handleCloseShareDialog = () => {
        setOpenShareDialog(false);
    };

    const handleDownloadAudio = async () => {
        if (getPlan().name === subscriptionPlans.free.name && !errorState) {
            setOpenPremiumDialog(true);
        } else {
            await downloadAudio({
                meetingTitle: meeting.title,
                audioFilename: meeting.filename,
                allowExportInformation: errorState,
                avoidFormatSuffix: meeting.transcriptionStatus === meetingStatus.STAND_BY
            });
        }
    };

    const deleteMeetingHandler = async () => {
        try {
            await handleDeleteMeeting(meeting.id);
            notification('deletion-success');
        } catch (err) {
            notification('deletion-failure');
        }
        setOpenDeleteDialog(false);
    };

    const handleUpdateTitle = async (title) => {
        try {
            // DB update
            await updateMeetingTitle({ meetingId: meeting.id, title });
            // Meeting update in our meeting list
            await findAndUpdateMeeting({ title, id: meeting.id });
            return true;
        } catch (error) {
            console.log(error);
            notification('title-error');
        }
    };

    return (
        <>
            <TableCell
                sx={{
                    borderColor: palette.primary[50]
                }}
                key={meeting.duration}
                style={{ width: 160 }}
                align='right'
            >
                {errorState ? (
                    <Grid
                        container
                        spacing={0.5}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}
                    >
                        {meeting.filename && meeting.filename !== null ? (
                            <Grid item xs={8}>
                                <Button
                                    color='primary'
                                    variant='outlined'
                                    disableElevation={true}
                                    size='small'
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        mr: 0,
                                        ml: -0.75
                                    }}
                                    onClick={
                                        userIsRunOutOfMinutes
                                            ? () => setOpenPremiumDialog(true)
                                            : () =>
                                                  navigate({
                                                      pathname: '/',
                                                      hash: navigation.app.hash.retry,
                                                      search: `?id=${meeting.id}`
                                                  })
                                    }
                                >
                                    Reintentar
                                </Button>
                            </Grid>
                        ) : null}
                        <Grid
                            item
                            xs={4}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <MenuButton
                                id='clickMeetingMenu'
                                iconButton={<MoreVertIcon id='clickMeetingMenu' fontSize='small' />}
                                tooltipText='Opciones'
                                menuList={[
                                    {
                                        itemFunction: () => setOpenEditTitleDialog(true),
                                        itemIcon: <EditOutlinedIcon fontSize='small' />,
                                        itemText: 'Editar nombre'
                                    },
                                    {
                                        itemClickId: 'clickDownloadAudioMenu',
                                        itemFunction: handleDownloadAudio,
                                        itemIcon: (
                                            <GraphicEqOutlinedIcon
                                                id='clickDownloadAudioMenu'
                                                fontSize='small'
                                            />
                                        ),
                                        itemText: 'Descargar audio',
                                        disabled:
                                            (meeting.filename && meeting.filename === null) ||
                                            meeting.transcriptionStatus ===
                                                meetingStatus.TRANSCRIPTION_STARTED ||
                                            meeting.transcriptionStatus ===
                                                meetingStatus.AUDIO_STARTED ||
                                            meeting.transcriptionStatus ===
                                                meetingStatus.AUDIO_IN_PROGESS ||
                                            meeting.transcriptionStatus === meetingStatus.STAND_BY
                                                ? true
                                                : false
                                    },
                                    {
                                        itemFunction: () => setOpenDeleteDialog(true),
                                        itemIcon: <DeleteOutlineOutlinedIcon fontSize='small' />,
                                        itemText: 'Eliminar'
                                    }
                                ]}
                            />
                        </Grid>
                    </Grid>
                ) : isStandBy ? (
                    <Grid
                        container
                        spacing={0.5}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}
                    >
                        {isRecording ? (
                            <TagMeetingProcessingStatus status={status} />
                        ) : (
                            <>
                                <Grid item xs={8}>
                                    <Button
                                        color='primary'
                                        variant='outlined'
                                        disableElevation={true}
                                        size='small'
                                        onClick={
                                            userIsRunOutOfMinutes
                                                ? () => setOpenPremiumDialog(true)
                                                : () =>
                                                      navigate({
                                                          pathname: '/',
                                                          hash: navigation.app.hash.retry,
                                                          search: `?id=${meeting.id}`
                                                      })
                                        }
                                    >
                                        Crear
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <MenuButton
                                        id='clickMeetingMenu'
                                        iconButton={
                                            <MoreVertIcon id='clickMeetingMenu' fontSize='small' />
                                        }
                                        tooltipText='Opciones'
                                        menuList={[
                                            {
                                                itemFunction: () => setOpenEditTitleDialog(true),
                                                itemIcon: <EditOutlinedIcon fontSize='small' />,
                                                itemText: 'Editar nombre'
                                            },
                                            {
                                                itemClickId: 'clickDownloadAudioMenu',
                                                itemFunction: handleDownloadAudio,
                                                itemIcon: (
                                                    <GraphicEqOutlinedIcon
                                                        id='clickDownloadAudioMenu'
                                                        fontSize='small'
                                                    />
                                                ),
                                                itemText: 'Descargar audio',
                                                disabled:
                                                    meeting.filename && meeting.filename !== null
                                                        ? false
                                                        : true
                                            },
                                            {
                                                itemFunction: () => setOpenDeleteDialog(true),
                                                itemIcon: (
                                                    <DeleteOutlineOutlinedIcon fontSize='small' />
                                                ),
                                                itemText: 'Eliminar'
                                            }
                                        ]}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                ) : (
                    <>
                        {isProcessing ? (
                            <TagMeetingProcessingStatus status={status} />
                        ) : (
                            <>
                                <MenuButton
                                    id='clickMeetingMenu'
                                    iconButton={
                                        <MoreVertIcon id='clickMeetingMenu' fontSize='small' />
                                    }
                                    tooltipText='Opciones'
                                    menuList={[
                                        {
                                            itemFunction: handleOpenMeetingPage,
                                            itemIcon: <CallMadeOutlinedIcon fontSize='small' />,
                                            itemText: 'Abrir reunión'
                                        },
                                        {
                                            itemFunction: handleOpenShareDialog,
                                            itemIcon: (
                                                <LinkOutlinedIcon
                                                    id='clickedShareButtonMenu'
                                                    fontSize='small'
                                                />
                                            ),
                                            itemText: 'Compartir',
                                            itemClickId: 'clickedShareButtonMenu'
                                        },
                                        {
                                            itemFunction: () => setOpenEditTitleDialog(true),
                                            itemIcon: <EditOutlinedIcon fontSize='small' />,
                                            itemText: 'Editar nombre'
                                        },
                                        {
                                            itemClickId: 'clickDownloadAudioMenu',
                                            itemFunction: handleDownloadAudio,
                                            itemIcon: (
                                                <GraphicEqOutlinedIcon
                                                    id='clickDownloadAudioMenu'
                                                    fontSize='small'
                                                />
                                            ),
                                            itemText: 'Descargar audio',
                                            disabled:
                                                meeting.filename && meeting.filename !== null
                                                    ? false
                                                    : true
                                        },
                                        {
                                            itemFunction: () => setOpenDeleteDialog(true),
                                            itemIcon: (
                                                <DeleteOutlineOutlinedIcon fontSize='small' />
                                            ),
                                            itemText: 'Eliminar'
                                        }
                                    ]}
                                />
                                {/* Below lines for conditional rendering */}
                                {openShareDialog ? (
                                    <ShareMeetingDialog
                                        withButton={false}
                                        meetingId={meeting.id}
                                        meetingTitle={meeting.title}
                                        openDialog={openShareDialog}
                                        closeDialog={handleCloseShareDialog}
                                    />
                                ) : null}
                            </>
                        )}
                    </>
                )}
                <ConfirmationDialog
                    open={openDeleteDialog}
                    title='Eliminar reunión'
                    message='Estas a punto de eliminar esta reunión, si la eliminas perderás toda su información.'
                    onConfirm={deleteMeetingHandler}
                    onClose={() => setOpenDeleteDialog(false)}
                />
                <PricingDialog
                    openDialog={openPremiumDialog}
                    setOpenDialog={setOpenPremiumDialog}
                />
                <DialogButton
                    dialogTitle='Título de la reunión'
                    dialogText='Edita el título de tu reunión para adaptarlo a tus necesidades'
                    withButton={false}
                    successButtonLoadingText='Actualizando...'
                    successButtonText='Actualizar'
                    onSuccess={() => handleUpdateTitle(newMeetingName)}
                    buttonSize='small'
                    fullWidthButton={true}
                    openDialog={openDialog}
                    onClose={() => setOpenEditTitleDialog(false)}
                >
                    <TextField
                        onInput={(e) => setNewMeetingName(e.target.value)}
                        value={newMeetingName}
                        style={{ marginTop: '15px' }}
                        fullWidth
                        placeholder='Nombre de tu reunión'
                        variant='standard'
                    />
                </DialogButton>
            </TableCell>
        </>
    );
}
