import { Dialog, DialogContent } from '@mui/material';
import Loader from '@common/components/loaders/Loader';

export default function DialogLoadingState() {
    return (
        <Dialog open={true} maxWidth={'sm'} fullWidth={true}>
            <DialogContent>
                <Loader minWidth={'20vh'} text='Estoy preparando tu audio, dame unos segundos 😊' />
            </DialogContent>
        </Dialog>
    );
}
