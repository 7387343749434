import React from 'react';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

const size = 8;

// StyledBadge definition
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#E84D47',
        color: '#E84D47',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        width: size, // Size of the badge
        height: size,
        borderRadius: '50%',
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""'
        }
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0
        }
    }
}));

// Isolated badge component
const StatusBadge = () => {
    return (
        <StyledBadge
            overlap='circular'
            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
            variant='dot'
            sx={{ ml: 1 }}
        >
            <div style={{ width: size, height: size }} /> {/* Invisible placeholder */}
        </StyledBadge>
    );
};

export default StatusBadge;
